<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        class="pb-16"
      >
        <PageTitleComponent title="비밀번호 변경" />
      </v-col>
    </v-row>
    <v-row
      justify="center"
    >
      <v-col
        cols="12"
        sm="6"
        md="4"
      >
        <p>
          현재 비밀번호 입력
        </p>
        <v-text-field
          ref="current_password"
          v-model.trim="currentPassword"
          outlined
          placeholder="현재 비밀번호를 입력해주세요"
          :rules="[rules.required]"
          :append-icon="currentPasswordVisible ? 'visibility' : 'visibility_off'"
          :type="currentPasswordVisible ? 'text' : 'password'"
          @click:append="currentPasswordVisible = !currentPasswordVisible"
        />
      </v-col>
    </v-row>
    <v-row
      justify="center"
    >
      <v-col
        cols="12"
        sm="6"
        md="4"
      >
        <p>
          새로운 비밀번호 입력
        </p>
        <v-text-field
          ref="new_password"
          v-model.trim="newPassword"
          outlined
          counter
          placeholder="새로운 비밀번호를 입력해주세요"
          hint="최소 8자 이상 입력해주세요"
          :rules="[rules.required, rules.min]"
          :append-icon="newPasswordVisible ? 'visibility' : 'visibility_off'"
          :type="newPasswordVisible ? 'text' : 'password'"
          @click:append="newPasswordVisible = !newPasswordVisible"
        />
      </v-col>
    </v-row>
    <v-row
      class="mb-5"
      justify="center"
    >
      <v-col cols="auto">
        <v-btn
          color="primary"
          @click="updatePassword"
        >
          수정하기
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PageTitleComponent from '../_elements/title/PageTitle'
import Api from './api/userApi'
import SignOut from '../account/models/signOut'

export default {
  components: {
    PageTitleComponent
  },
  data() {
    return {
      isLoading: false,
      currentPassword: '',
      currentPasswordVisible: false,
      newPassword: '',
      newPasswordVisible: false,
      rules: {
        required: (v) => !!v || 'Required.',
        min: (v) => v.length >= 8 || 'Min 8 characters'
      }
    }
  },
  methods: {
    async validate() {
      let result = false
      if (this.currentPassword === '') {
        this.$bus.$emit('errorMessage', '현재 비밀번호를 입력해주세요')
        this.$refs.current_password.focus()
      } else if (this.newPassword === '') {
        this.$bus.$emit('errorMessage', '새로운 비밀번호를 입력해주세요')
        this.$refs.new_password.focus()
      } else {
        result = true
      }
      return result
    },
    async updatePassword() {
      if (await this.validate() === false) {
        return
      }
      const result = await Api.changeMyPassword(
        this.currentPassword,
        this.newPassword
      )
      if (result === true) {
        this.$bus.$emit('successMessage', '패스워드가 변경되었습니다. 다시 로그인 해 주세요')
        const signOutResult = await SignOut.signOut(this)
        if (signOutResult === true) {
          this.$router.push('/')
        }
      } else {
        this.$bus.$emit('successMessage', '서버 통신에 문제가 발생했습니다')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
